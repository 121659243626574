import { vars } from "@pancakeswap/ui/css/vars.css";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <a href="https://hashfair.games">
      <img width={50} src="/brandlogo.png" className={props.className} />
    </a>
  );
 
};

export default Logo;
