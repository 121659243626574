import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {


  const handleClick = () => {
    window.location.href = "https://hashfair.games";
  };
  return <img width={50} src="/brandlogo.png" className={props.className} onClick={handleClick} style={{cursor: "pointer"}} />;

};

export default Icon;
